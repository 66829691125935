import React from 'react';
import MarkdownPage from '../../features/markdownPage/MarkdownPage';
import styles from './Nutzungsbedingungen.module.css';

/*
 * Renders Page with open source licenses
*/

const Nutzungsbedingungen: React.FC = () => (
  <MarkdownPage pageKey="nutzungsbedingungen" className={styles.nutzungsbedingungenContainer} />
);

export default Nutzungsbedingungen;
