import keycloak from '../keycloak';

/**
 * wrapper class for storing api error response
 */
class APIError extends Error {
  constructor(
    public code: number,
    public status: string,
    public details: Response,
  ) {
    super(`[${code}]: ${status}`);
  }
}

/**
 * Wrapper over {@link fetch} to handle api response errors.
 */
const api: typeof fetch = async (info, init, ...args) => {
  // set auth header to the keycloak token in the request init object
  const reqInit = {
    ...init,
    headers: new Headers({
      ...init?.headers,
      ...(keycloak.token ? {
        Authorization: `Bearer ${keycloak.token}`,
      } : {}),
    }),
  };

  const response = await fetch(info, reqInit, ...args);
  if (!response.ok) {
    throw new APIError(response.status, response.statusText, response);
  }

  return response;
};

/**
 * Makes a request and returns dataUri of the response body
 * (Used for fetching pdf)
 * @param url the url of the resource
 */
const fetchDataUri = (url: string): Promise<string> => api(url)
  .then((response) => response.blob())
  .then((blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(blob);
  }));

export {
  fetchDataUri,
  api,
  APIError,
};

export const s = (...args: (string | null)[]): string => args.filter((arg) => arg !== null).join(' ');
