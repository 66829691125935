import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
// import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { PrimaryButton } from '../button/Button';
import styles from './Profile.module.css';
import textStyles from '../text/Text.module.css';
import { s } from '../../app/helper';

const Profile: React.FC = () => {
  // const navigate = useNavigate();
  const [commonT] = useTranslation('common');
  const { initialized: kcInit, keycloak } = useKeycloak();

  const logoutCallback = useCallback(
    async () => {
      await keycloak?.logout();
      console.log('memoized logout callback called');
    },
    [],
  );

  /* triggers user update */

  if (!kcInit) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }

  return (
    <div className={styles.profile}>
      <PrimaryButton
        onClick={() => {
          logoutCallback();
        }}
        className={s(styles.logoutButton, textStyles.HeaderPages)}
      >
        {commonT('log-out')}
      </PrimaryButton>
    </div>
  );
};
export default Profile;
