import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { topicIdsToIcons } from '../../app/topicIcons';
import DummyIconMaterials from '../../res/materials.svg';

import styles from './TopicButton.module.css';
import fontStyles from '../text/Text.module.css';

import { s } from '../../app/helper';

export interface TopicButtonProps {
  iconID: string,
  onButtonClick: () => void,
  isSelected: boolean,
}

const TopicButton: React.FC<TopicButtonProps> = ({
  iconID, onButtonClick, isSelected,
}) => {
  const icon = topicIdsToIcons.get(iconID) || DummyIconMaterials;
  const [t] = useTranslation('topics');
  return (
    <div
      className={s(styles.topicButton, isSelected ? styles.topicSelected : '')}
      onClick={onButtonClick}
      aria-hidden="true"
    >
      <div>
        <Image src={icon} className={styles.topicButtonIcon} />
      </div>
      <div className={s(styles.topicButtonTitle, fontStyles.smallButton)}>
        {t(iconID)}
      </div>
    </div>
  );
};

export default TopicButton;
