import { api } from './helper';

enum JobStatus {
  QUEUED = 'queued',
  FINISHED = 'finished',
  FAILED = 'failed',
  STARTED = 'started',
  DEFERRED = 'deferred',
  SCHEDULED = 'scheduled',
  STOPPED = 'stopped',
  CANCELED = 'canceled',
}

export interface Job {
  status: JobStatus,
  progress: number,
  info: string,
}

/**
 * Requests Job status from server
 * @param url the url of the resource
 */
const getJob = (jobid: string): Promise<Job> => {
  const url = `/api/v1/job/${jobid}`;
  return api(url).then((response) => response.json()).then((job) => job as Job);
};

export {
  getJob,
  JobStatus,
};
