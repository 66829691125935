/* eslint-disable no-underscore-dangle */
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch, faArrowRight, faArrowUp, faArrowDown, faCircleCheck, faCircleXmark, faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Blob, Recommendation } from '../../app/datatypes';
import { SmallSecondaryButton } from '../button/Button';

import styles from './BlobRender.module.css';
import fontStyles from '../text/Text.module.css';
import { s } from '../../app/helper';
import { deleteFeedback, FeedbackState, postFeedback } from '../../reducer/reportSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

/**
 * interface for blob render props
 */
interface BlobProps {
  blob: Blob;
  recommendation: Recommendation;
  feedback?: FeedbackState;
  jumpToBlob: (blobIds: Set<number>, page: number) => void;
  showAccuracy?: boolean;
}

/**
 * Render a blob
 * @param blob is a blob object.
 * @param jumpToBlob function called when blob should be jumped to.
 */
const BlobRender = (
  {
    blob,
    recommendation,
    feedback,
    jumpToBlob,
    showAccuracy,
  }: BlobProps,
): JSX.Element => {
  const dispatch = useAppDispatch();
  const report = useAppSelector((state) => state.report.report);

  // enables translations
  const [t] = useTranslation('blob_render');
  const blobId = blob.id_;
  const [showAll, setShowAll] = useState<boolean>(false);
  const blobTooLong = blob.content.length >= 300;

  const blobContentDisplay = (
    <>
      <pre data-testid="blob-content" className={s(fontStyles.text, styles.blobDisplayContent)}>
        {blobTooLong && !showAll ? `${blob.content.slice(0, 301)} …` : blob.content}
      </pre>
      {blobTooLong ? (
        <SmallSecondaryButton
          data-testid={showAll ? 'less-button' : 'more-button'}
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? t('less-content') : t('more-content')}
        </SmallSecondaryButton>
      ) : null}
    </>
  );

  return (
    <div
      className={styles.containerBlob}
      id={`blob-${blob.id_}`}
      data-testid={`blob-${blobId}`}
    >
      {showAccuracy && (
        <div className={styles.blobConfidence}>
          <span className={s(styles.blobConfidencePercent, fontStyles.SubHeaderPopups)}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!recommendation.confidence
              ? <FontAwesomeIcon icon={faArrowRight} />
              : (recommendation.confidence === 'certain'
                ? <FontAwesomeIcon icon={faArrowUp} />
                : <FontAwesomeIcon icon={faArrowDown} />)}
          </span>
          <span className={s(styles.blobConfidenceSubtext, fontStyles.smallButton)}>
            {t('accuracy')}
          </span>
        </div>
      )}
      <OverlayTrigger
        trigger={['hover', 'click']}
        key="jump-to-blob-overlay"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{t('show-blob-in-pdf')}</Tooltip>}
      >
        <div
          className={styles.blobContent}
          onClick={() => jumpToBlob(new Set([blobId]), blob.page)}
          aria-hidden="true"
        >
          {blobContentDisplay}
        </div>
      </OverlayTrigger>
      <div className={styles.blobButtons}>
        <OverlayTrigger
          trigger={['hover', 'click']}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip>
              {feedback?.feedback && feedback.feedback.value
                ? t('reset')
                : t('confirm')}
            </Tooltip>
          )}
        >
          <Button
            disabled={feedback?.status === 'loadingFalse'}
            className={s(
              styles.blobButton,
              feedback?.feedback && feedback.feedback.value ? styles.activeBlobButton : '',
            )}
            onClick={() => {
              if (!report
                || feedback?.status === 'loadingTrue') return;

              if (feedback?.feedback && feedback.feedback.value) {
                dispatch(deleteFeedback({
                  report_id: report._id,
                  requirement_id: recommendation.requirement_id,
                  blob_id: blobId,
                }));
                return;
              }

              dispatch(postFeedback({
                report_id: report._id,
                requirement_id: recommendation.requirement_id,
                blob_id: blobId,
                value: true,
              }));
            }}
          >
            {feedback?.status === 'loadingTrue'
              ? <FontAwesomeIcon icon={faCircleNotch} spin />
              : (
                <>
                  <FontAwesomeIcon
                    className={s(
                      styles.feedbackIcon,
                      feedback?.feedback && feedback.feedback.value ? styles.iconHoverInvisible : '',
                    )}
                    icon={faCircleCheck}
                    color={feedback?.feedback && feedback.feedback.value ? '#26d44c' : 'white'}
                  />
                  <FontAwesomeIcon
                    className={s(
                      styles.resetIcon,
                      feedback?.feedback && feedback.feedback.value ? styles.iconHoverVisible : '',
                    )}
                    icon={faRotateLeft}
                    color="white"
                  />
                </>
              )}
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={['hover', 'click']}
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip>
              {feedback?.feedback && !feedback.feedback.value
                ? t('reset')
                : t('reject')}
            </Tooltip>
          )}
        >
          <Button
            disabled={feedback?.status === 'loadingTrue'}
            className={s(
              styles.blobButton,
              feedback?.feedback && !feedback.feedback.value ? styles.activeBlobButton : '',
            )}
            onClick={() => {
              if (!report
                || feedback?.status === 'loadingFalse') return;

              if (feedback?.feedback && !feedback.feedback.value) {
                dispatch(deleteFeedback({
                  report_id: report._id,
                  requirement_id: recommendation.requirement_id,
                  blob_id: blobId,
                }));
                return;
              }

              dispatch(postFeedback({
                report_id: report._id,
                requirement_id: recommendation.requirement_id,
                blob_id: blobId,
                value: false,
              }));
            }}
          >
            {feedback?.status === 'loadingFalse'
              ? <FontAwesomeIcon icon={faCircleNotch} spin />
              : (
                <>
                  <FontAwesomeIcon
                    className={s(
                      styles.feedbackIcon,
                      feedback?.feedback && !feedback.feedback.value ? styles.iconHoverInvisible : '',
                    )}
                    icon={faCircleXmark}
                    color={feedback?.feedback && !feedback.feedback.value ? '#de0b0b' : 'white'}
                  />
                  <FontAwesomeIcon
                    className={s(
                      styles.resetIcon,
                      feedback?.feedback && !feedback.feedback.value ? styles.iconHoverVisible : '',
                    )}
                    icon={faRotateLeft}
                    color="white"
                  />
                </>
              )}
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

BlobRender.defaultProps = {
  showAccuracy: true,
  feedback: undefined,
};

export default BlobRender;
