import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Image, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import { api, s } from '../../app/helper';
import fontStyles from '../text/Text.module.css';
import styles from './ReportPreviewCard.module.css';
import { SmallSecondaryButton, SmallSecondaryOutlineButton } from '../button/Button';
import { Report } from '../../app/datatypes';
import { getJob, Job, JobStatus } from '../../app/job';
import ReportPreview from '../reportPreview/ReportPreview';

type ReportStatusBannerProps = {
  report: Report
};

const ReportStatusBanner: React.FC<ReportStatusBannerProps> = ({
  report,
}) => {
  const [job, setJob] = useState<Job | null>(null);
  const [t] = useTranslation('report_card');

  const fetchJob = (jobid: string): void => {
    getJob(jobid).then((j) => setJob(j));
  };

  useEffect(() => {
    // query the job for the first time
    fetchJob(report.job_id);
  }, [report]);

  useEffect(() => {
    // schedule refresh of job data
    const timeout = setTimeout(
      () => { fetchJob(report.job_id); },
      10000,
    );
    // cleaning up timeout
    return () => { clearTimeout(timeout); };
  }, [job, report]);

  if (job === null) {
    return null;
  }

  let content = null;
  if (job.status === JobStatus.QUEUED) {
    content = t('queued');
  } else if (job.status === JobStatus.STARTED || job.status === JobStatus.FINISHED) {
    // running
    content = (
      <>
        {job.status === JobStatus.STARTED ? t('running') : t('done')}
        <ProgressBar className={s(styles.cardProgress)}>
          <ProgressBar className={s(styles.cardProgressFill)} now={job.progress} />
        </ProgressBar>
      </>
    );
  } else {
    // error
    content = t('error');
  }

  return (
    <div className={s(styles.reportCardBanner, fontStyles.text)}>
      {content}
    </div>
  );
};

type ReportPreviewCardProps = {
  report: Report
};

const ReportPreviewCard: React.FC<ReportPreviewCardProps> = ({
  report,
}) => {
  const [t] = useTranslation('report_card');
  const [imageURL, setImageURL] = useState<string | null>(null);
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [previewReportId, setPreviewReportId] = useState<string | null>(null);

  const showBanner = !report.available;

  // gets the report thumbnail
  useEffect(
    () => {
      // eslint-disable-next-line no-underscore-dangle
      api(`/api/v1/report/${report._id}/thumbnail`).then((resp) => resp.blob())
        .then((bl) => setImageURL(URL.createObjectURL(bl)));
    },
    // eslint-disable-next-line no-underscore-dangle
    [report._id],
  );

  return (
    <Card
      className={s(styles.reportCard)}
    >
      <Image
        className={styles.thumbnail}
        src={imageURL || undefined}
        onLoad={() => { setImageLoaded(true); }}
      />
      {imageLoaded ? null : (
        <>
          <Spinner animation="border" />
          Loading
        </>
      )}
      { showBanner && (
        <ReportStatusBanner report={report} />
      )}
      <div className={styles.reportCardContent}>
        <ReportPreview
          reportId={previewReportId}
          onHide={() => setPreviewReportId(null)}
        />
        <div className={s(styles.reportTitle, fontStyles.SubHeaderPopups)}>{report.company}</div>
        <div className={s(styles.reportSubTitle, fontStyles.text)}>{report.year}</div>
        <div className={styles.buttonBar}>
          <SmallSecondaryOutlineButton onClick={() => {
            // eslint-disable-next-line no-underscore-dangle
            setPreviewReportId(report?._id || null);
          }}
          >
            {t('preview')}
          </SmallSecondaryOutlineButton>
          <SmallSecondaryButton
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              navigate(`/report/${report._id}/`);
            }}
          >
            {t('select')}
          </SmallSecondaryButton>
        </div>
      </div>
    </Card>
  );
};

export default ReportPreviewCard;

type ReportPreviewAddProps = {
  onClick: () => void;
};

export const ReportPreviewAdd: React.FC<ReportPreviewAddProps> = ({ onClick }) => {
  const [t] = useTranslation('report_card');
  return (
    <Card className={s(styles.reportCard, styles.addCard)} onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} size="2x" />
      <div className={s(fontStyles.text, styles.addDocText)}>{t('add-document')}</div>
    </Card>
  );
};
