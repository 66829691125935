import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Prediction } from '../app/datatypes';
import { api } from '../app/helper';

export interface PredictionState {
  status: 'idle' | 'success' | 'loading' | 'error';
  prediction: Prediction | null;
  error: string;
}

const initialState = {
  status: 'idle',
  prediction: null,
  error: '',
} as PredictionState;

/**
 * Gets a single prediction using the report_id from the REST API
 * @param document_id the id of the document
 */
export const getPrediction = createAsyncThunk<Prediction, {
  report_id: string;
}>('prediction/get_by_report', ({ report_id }) => api(`/api/v1/report/${report_id}/prediction`)
  .then((r) => r.json())
  .then((r) => r as Prediction));

export const predictionSlice = createSlice({
  name: 'prediction',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getPrediction.pending, (state) => {
      state.status = 'loading';
      state.prediction = null;
    }).addCase(getPrediction.fulfilled, (state, action) => {
      state.prediction = action.payload;
      state.error = '';
      state.status = 'success';
    }).addCase(getPrediction.rejected, (state, action) => {
      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
    });
  },
});

export const {
  clear,
} = predictionSlice.actions;

export default predictionSlice.reducer;
