import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-bootstrap';

import { store } from './app/store';

import Report from './pages/report/Report';
import Reports from './pages/reports/Reports';
import AppPage from './features/appPage/AppPage';
import IntroPage from './pages/intro/IntroPage';
import Oss from './pages/oss/Oss';
import LandingLoginPage from './pages/landingLoginPage/LandingLoginPage';
import Datenschutz from './pages/datenschutz/Datenschutz';
import Impressum from './pages/impressum/Impressum';
import Nutzungsbedingungen from './pages/nutzungsbedingungen/Nutzungsbedingungen';

import appStyles from './css/App.module.css';

type ProtectRouteProps = {
  onNotAuthenticatedRedirectURL: string
};

const ProtectRoute: React.FC<ProtectRouteProps> = ({ onNotAuthenticatedRedirectURL }) => {
  const { keycloak } = useKeycloak();
  if (keycloak.authenticated) {
    return <Outlet />;
  }
  return <Navigate to={onNotAuthenticatedRedirectURL} />;
};

const App: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
        minBreakpoint="xs"
      >
        <div data-testid="app-main-element" className={[appStyles.mainContainer, appStyles.App].join(' ')}>
          <Routes>
            <Route path="/" element={<LandingLoginPage />} />
            <Route path="/impressum" element={<AppPage />}>
              <Route
                path="/impressum"
                element={<Impressum />}
              />
            </Route>
            <Route path="/datenschutz" element={<AppPage />}>
              <Route
                path="/datenschutz"
                element={<Datenschutz />}
              />
            </Route>
            <Route path="/nutzungsbedingungen" element={<AppPage />}>
              <Route
                path="/nutzungsbedingungen"
                element={<Nutzungsbedingungen />}
              />
            </Route>
            <Route element={<ProtectRoute onNotAuthenticatedRedirectURL="/" />}>
              <Route path="/intro" element={<AppPage />}>
                <Route
                  path="/intro"
                  element={<IntroPage />}
                />
              </Route>
              <Route path="/oss" element={<AppPage />}>
                <Route
                  path="/oss"
                  element={<Oss />}
                />
              </Route>
              <Route path="/report/:reportId/" element={<AppPage fixedHeight addMargin={false} />}>
                <Route path="/report/:reportId/" element={<Report />} />
              </Route>
              <Route path="/report" element={<AppPage />}>
                <Route path="/report" element={<Reports />} />
              </Route>
            </Route>
          </Routes>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
