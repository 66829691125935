import Keycloak from 'keycloak-js';

export type StoredToken = {
  idToken?: string,
  token?: string,
  refreshToken?: string,
};

export function storeToken(tokens: StoredToken) : void {
  localStorage.setItem('tokendata', JSON.stringify(tokens));
}

export function getToken() : StoredToken {
  const obj = localStorage.getItem('tokendata');
  if (obj) {
    return JSON.parse(obj) as StoredToken;
  }
  return {} as StoredToken;
}

const keycloak = new Keycloak('/api/v1/kc-config');

export default keycloak;
