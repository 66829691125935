import React, { PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import { s } from '../../app/helper';
import styles from './BlurModal.module.css';

type BlurModalProps = {
  show?: boolean;
  onHide?: () => void;
  modalClassName?: string;
  backdropClassName?: string;
} & PropsWithChildren;

const BlurModal: React.FC<BlurModalProps> = ({
  show,
  onHide,
  children,
  modalClassName,
  backdropClassName,
}) => (
  <Modal
    show={show}
    onHide={onHide}
    className={s(styles.blurModal, modalClassName!)}
    backdropClassName={s(styles.blurModalBackdrop, backdropClassName!)}
  >
    {children}
  </Modal>
);

BlurModal.defaultProps = {
  show: false,
  onHide: () => {
  },
  modalClassName: '',
  backdropClassName: '',
};

export default BlurModal;
