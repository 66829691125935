import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../navBar/NavBar';
import FooterNav from '../footerNav/FooterNav';
import { s } from '../../app/helper';
import styles from './AppPage.module.css';
import Profile from '../profile/Profile';
import MobileModal from '../mobileModal/MobileModal';

const Header: React.FC<{ children?: React.ReactNode; }> = (
  {
    children,
  },
) => (
  <div className={styles.header}>
    {children}
  </div>
);

Header.defaultProps = {
  children: false,
};

const Footer: React.FC<{ children?: React.ReactNode; }> = (
  {
    children,
  },
) => (
  <div className={styles.footer}>
    {children}
  </div>
);

Footer.defaultProps = {
  children: false,
};

interface BackgroundProps {
  fixedHeight: boolean,
  children?: React.ReactNode,
}

export const Background: React.FC<BackgroundProps> = ({ children, fixedHeight }) => (
  <div className={fixedHeight ? styles.bodyNoScroll : styles.body}>
    {children}
    <MobileModal />
  </div>
);

Background.defaultProps = {
  children: null,
};

interface AppPageProps {
  fixedHeight?: boolean,
  addMargin?: boolean
}

const AppPage: React.FC<AppPageProps> = ({ fixedHeight, addMargin }) => {
  const containerClasses = s(
    fixedHeight ? styles.contentContainerNoScroll : styles.contentContainer,
    addMargin ? styles.contentMargin : null,
  );

  return (
    <Background fixedHeight={fixedHeight!}>
      <Header>
        <NavBar />
        <Profile />
      </Header>
      <div className={containerClasses}>
        <Outlet />
      </div>
      <Footer>
        <FooterNav />
      </Footer>
    </Background>
  );
};

AppPage.defaultProps = {
  addMargin: true,
  fixedHeight: false,
};

export default AppPage;
