import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Checklist } from '../app/datatypes';
import { api } from '../app/helper';

export interface ChecklistState {
  status: 'idle' | 'success' | 'loading' | 'error';
  checklist: Checklist | null;
  error: string;
}

const initialState = {
  status: 'idle',
  checklist: null,
  error: '',
} as ChecklistState;

/**
 * Gets a single checklist using the checklist_id from the REST API
 * @param checklist_id the id of the checklist
 */
export const getChecklist = createAsyncThunk<Checklist, {
  checklist_id: string;
}>('checklist/get_by_id', ({ checklist_id }) => api(`/api/v1/checklist/${checklist_id}`)
  .then((r) => r.json())
  .then((r) => r as Checklist));

export const checklistSlice = createSlice({
  name: 'checklist',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getChecklist.pending, (state) => {
      state.status = 'loading';
      state.checklist = null;
    }).addCase(getChecklist.fulfilled, (state, action) => {
      state.checklist = action.payload;
      state.error = '';
      state.status = 'success';
    }).addCase(getChecklist.rejected, (state, action) => {
      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
    });
  },
});

export const {
  clear,
} = checklistSlice.actions;

export default checklistSlice.reducer;
