import React from 'react';
import {
  Nav, Navbar,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import styles from './NavBar.module.css';
import { s } from '../../app/helper';

const NavBar: React.FC = () => {
  const [t] = useTranslation('common');
  const location = useLocation();
  return (
    <Navbar bg="transparent" variant="dark" className={styles.navbar}>
      <Nav activeKey={location.pathname} className={styles.navbarNav}>
        <LinkContainer
          to="/report"
        >
          <Navbar.Brand className={styles.navbarLogo}>
            <div className={styles.appname}>Sustain.AI</div>
          </Navbar.Brand>
        </LinkContainer>
        <LinkContainer
          to="/intro"
          className={({ isActive }: { isActive: boolean }) => (
            isActive
              ? s(styles.navLink, styles.navLinkActive)
              : s(styles.navLink))}
        >
          <Nav.Link>{t('introduction')}</Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/report"
          className={({ isActive }: { isActive: boolean }) => (
            isActive
              ? s(styles.navLink, styles.navLinkActive)
              : s(styles.navLink))}
        >
          <Nav.Link>{t('pick-a-report')}</Nav.Link>
        </LinkContainer>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
