/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Container, Row, Col, Image, Ratio,
} from 'react-bootstrap';
import EconomyIcon from '../../res/economy.svg';
import EnvironmentalIcon from '../../res/environmental.svg';
import IntroImage from '../../res/introImage.png';
import SocialIcon from '../../res/social.svg';
import styles from './IntroPage.module.scss';
import fontStyles from '../../features/text/Text.module.css';
import { WhiteButton } from '../../features/button/Button';
import { s } from '../../app/helper';

type SectionProps = {
  sectionName: string,
  children: React.ReactNode,
};

const Section: React.FC<SectionProps> = ({ sectionName, children }) => (
  <>
    <Row className={s(styles.sectionHeader, styles.introductionRow, fontStyles.HeaderPages)}>{sectionName}</Row>
    {children}
  </>
);

const RegulationSection: React.FC = () => {
  const [t] = useTranslation('intro');
  return (
    <Section
      sectionName={t('regulationsHeader')}
    >
      <Row className={s(fontStyles.text, styles.introductionRow)}>
        <Col xl={12} xxl={7} className={styles.noMarginCol}>
          <div>
            {t('regulationsText')}
          </div>
          <br />
          <div>
            {t('categoryText')}
          </div>
        </Col>
      </Row>

    </Section>
  );
};

type CategoryProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  categoryName: string,
  // eslint-disable-next-line react/no-unused-prop-types
  categoryIcon: string,
  // eslint-disable-next-line react/no-unused-prop-types
  categoryText: string
};

const CategoryHeader: React.FC<CategoryProps> = ({ categoryName, categoryIcon }) => (
  <Col md={4} sm={12}>
    <div className={styles.categoryHeader}>
      <Image src={categoryIcon} className={styles.icon} />
      <div className={s(fontStyles.SubHeaderPages)}>
        {categoryName}
      </div>
    </div>
  </Col>
);

const CategoryDescription: React.FC<CategoryProps> = ({ categoryText }) => (
  <Col md={4} sm={12}>
    <div>
      {categoryText}
    </div>
  </Col>
);

const CategoriesSection: React.FC = () => {
  const [t] = useTranslation('intro');
  const categories = [
    { categoryIcon: SocialIcon, categoryName: t('socialHeader'), categoryText: t('socialText') } as CategoryProps,
    {
      categoryIcon: EnvironmentalIcon,
      categoryName: t('environmentHeader'),
      categoryText: t('environmentText'),
    } as CategoryProps,
    { categoryIcon: EconomyIcon, categoryName: t('economicHeader'), categoryText: t('economicText') } as CategoryProps,
  ];

  return (
    <>
      <Row className={s(fontStyles.text, styles.introductionRow)}>
        {categories.map((catProps) => (
          <CategoryHeader
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...catProps}
          />
        ))}
      </Row>
      <Row className={s(fontStyles.text, styles.introductionRow)}>
        {categories.map((catProps) => (
          <CategoryDescription
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...catProps}
          />
        ))}
      </Row>
    </>
  );
};

const HowToSection: React.FC = () => {
  const [t] = useTranslation('intro');
  return (
    <Section
      sectionName={t('howToHeader')}
    >
      <Row className={s(fontStyles.text, styles.introductionRow, styles.howToRow, 'g-4')}>
        <Col xl={12} xxl={6} xxxl={7}>
          <iframe
            width="100%"
            className={styles.ytFrame}
            src="https://www.youtube-nocookie.com/embed/KfxRAlHyi6E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            allowFullScreen
          />
        </Col>
        <Col xl={12} xxl={6} xxxl={5} className={styles.howToTextCol}>
          {t('howToText').split('\n').map((paragraph, inx) => (
            // eslint-disable-next-line react/no-array-index-key
            <p className={s(styles.videoDesc, fontStyles.text)} key={`paragraph-${inx}`}>
              {paragraph}
            </p>
          ))}
        </Col>
      </Row>
    </Section>
  );
};

const TechnicalBackground: React.FC = () => {
  const [t] = useTranslation('intro');
  return (
    <Section
      sectionName={t('technicalHeader')}
    >
      <Row className={s(fontStyles.text, styles.introductionRow)}>
        {t('technicalText')}
      </Row>
    </Section>
  );
};

function IntroPage(): JSX.Element {
  const navigate = useNavigate();
  const [t] = useTranslation('intro');
  const headerParagraphs = t('welcomeText').split('\n');

  return (
    <Container fluid className={styles.introPage}>
      <Row
        key="wrow"
        className={s(styles.pageTitle, styles.introductionRow, fontStyles.HeaderPages)}
      >
        {t('welcome')}
      </Row>
      <Row>
        <Col>
          <Image src={IntroImage} className={s(styles.introImage)} />
          {headerParagraphs.map((paragraph, pid) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row className={s(styles.introductionRow, fontStyles.text)}>{paragraph}</Row>
          ))}
        </Col>
      </Row>
      <WhiteButton
        onClick={() => navigate('/report')}
        className={styles.buttonContinue}
      >
        {t('start')}
      </WhiteButton>
      <RegulationSection />
      <CategoriesSection />
      <HowToSection />
      <TechnicalBackground />
    </Container>
  );
}

export default IntroPage;
