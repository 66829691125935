import React from 'react';
import BlurModal from '../blurModal/BlurModal';

import styles from './LoadingModal.module.css';
import fontStyles from '../text/Text.module.css';
import { s } from '../../app/helper';
import { ReactComponent as Loading } from '../../res/loading.svg';

type LoadingModalProps = {
  show?: boolean;
};

const LoadingModal: React.FC<LoadingModalProps> = ({ show }) => (
  <BlurModal
    show={show}
  >
    <div className={s(styles.loadingModal, fontStyles.SubHeaderPopups)}>
      <Loading className={s(styles.loadingIcon, styles.rotate)} />
      <span className={styles.loadingLabel}>Loading…</span>
    </div>
  </BlurModal>
);

LoadingModal.defaultProps = {
  show: false,
};

export default LoadingModal;
