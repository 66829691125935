import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchDataUri } from '../app/helper';

export interface PdfState {
  status: 'idle' | 'success' | 'loading' | 'error';
  pdf: string | null;
  error: string;
}

const initialState = {
  status: 'idle',
  pdf: null,
  error: '',
} as PdfState;

const fetchPdf = (url: string): Promise<string> => fetchDataUri(url).then((dataUri) => {
  if (!dataUri.startsWith('data:application/pdf;base64,')) throw new Error('not a pdf document');
  return dataUri;
});

/**
 * Fetches a pdf from a report
 * @param report_id the if of the report
 */
export const getPdf = createAsyncThunk<string, {
  report_id: string;
}>(
  'pdf/get_by_report_id',
  async ({ report_id }) => fetchPdf(`/api/v1/report/${report_id}/pdf`),
);

export const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getPdf.pending, (state: PdfState) => {
      state.status = 'loading';
      state.pdf = null;
    }).addCase(getPdf.fulfilled, (state: PdfState, action) => {
      state.pdf = action.payload;
      state.error = '';
      state.status = 'success';
    }).addCase(getPdf.rejected, (state: PdfState, action) => {
      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
    });
  },
});

export const {
  clear,
} = pdfSlice.actions;

export default pdfSlice.reducer;
