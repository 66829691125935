import * as en from './en';
import * as de from './de';

export {
  en,
  de,
};

// should contain the required keys as long as `en` has all the keys
export default Object.keys(en);
