/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ReportPreviewCard, { ReportPreviewAdd } from '../../features/reportSelectors/ReportPreviewCard';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ReportPreview from '../../features/reportPreview/ReportPreview';
import UploadDocument from '../../features/upload/UploadDocument';
import { getReports } from '../../reducer/reportsSlice';
import { s } from '../../app/helper';

import styles from './Reports.module.css';
import fontStyles from '../../features/text/Text.module.css';

const totalNumPrivateReports = 5;

const Reports: React.FC = () => {
  const { initialized, keycloak } = useKeycloak();
  const dispatch = useAppDispatch();
  const reports = useAppSelector((state) => state.reports.reports);
  const [showUploadView, setShowUploadView] = useState(false);

  const [t] = useTranslation('report_selector');

  useEffect(() => {
    dispatch(getReports());
  }, [dispatch]);

  const publicPreviews = reports.filter((report) => report.owner === null).map((report) => (
    <Col
      xs={6}
      md={4}
      xl={3}
      xxxl={2}
      // eslint-disable-next-line no-underscore-dangle
      key={`report-preview-${report._id}`}
    >
      <ReportPreviewCard report={report} />
    </Col>
  ));

  // renders the existing private reports
  const privateReports = reports.filter((report) => report.owner !== null).map((report) => (
    <ReportPreviewCard report={report} />
  ));

  // adds placeholders for not uploaded reports
  const privateReportsPlaceholder = [...Array(totalNumPrivateReports - privateReports.length)].map((x, i) => (
    <ReportPreviewAdd
      onClick={() => {
        setShowUploadView(true);
      }}
    />
  ));
  const privatePreviews = [...privateReports, ...privateReportsPlaceholder].map((x, i) => (
    <Col
      xs={6}
      md={4}
      xl={3}
      xxxl={2}
      // eslint-disable-next-line react/no-array-index-key
      key={`report-preview-${i}`}
    >
      {x}
    </Col>
  ));

  return (
    <Container className={styles.filesPage} fluid>
      <UploadDocument
        onHide={() => setShowUploadView(false)}
        showUploadDocument={showUploadView}
      />
      <div className={s(fontStyles.HeaderPopups, styles.reportHeader)}>
        {t('reports')}
      </div>
      <div className={s(fontStyles.text, styles.reportDescription)}>
        {t('subtitle')}
      </div>

      <div className={s(fontStyles.SubHeaderPages, styles.reportSubheader)}>
        {t('public_reports')}
      </div>
      <Row className={styles.reportsRow}>
        {publicPreviews}
      </Row>

      <div className={s(fontStyles.SubHeaderPages, styles.reportSubheader)}>
        {t('your_reports')}
      </div>

      <div className={s(fontStyles.text, styles.reportSubheader)}>
        {t('details_your_reports')}
      </div>
      <Row className={styles.reportsRow}>
        {privatePreviews}
      </Row>
    </Container>
  );
};

export default Reports;
