/* eslint-disable react/prop-types,react/jsx-props-no-spreading */
import React from 'react';
import { Button } from 'react-bootstrap';
import { s } from '../../app/helper';

import styles from './Button.module.css';
import fontStyles from '../text/Text.module.css';

const PrimaryButton: typeof Button = ({ className, ...props }) => (
  <Button
    className={s(className || '', styles.darkgreen, styles.primary, fontStyles.button)}
    {...props}
  />
);

const LightGreenButton: typeof Button = ({ className, ...props }) => (
  <Button
    className={s(className || '', styles.lightgreen, styles.primary, fontStyles.button)}
    {...props}
  />
);

const SecondaryButton: typeof Button = ({ className, ...props }) => (
  <Button
    className={s(className || '', styles.secondary, fontStyles.button)}
    {...props}
  />
);

const SecondaryOutlineButton: typeof Button = ({ className, ...props }) => (
  <Button
    className={s(className || '', styles.secondaryOutline, fontStyles.button)}
    {...props}
  />
);

const WhiteButton: typeof Button = ({ className, ...props }) => (
  <Button
    className={s(className || '', styles.whiteButton, fontStyles.button)}
    {...props}
  />
);

const SmallSecondaryButton: typeof Button = ({ className, ...props }) => (
  <Button
    className={s(className || '', styles.secondary, styles.smallButton, fontStyles.smallButton)}
    {...props}
  />
);

const SmallSecondaryOutlineButton: typeof Button = ({ className, ...props }) => (
  <Button
    className={s(className || '', styles.secondaryOutline, styles.smallButton, fontStyles.smallButton)}
    {...props}
  />
);

export {
  PrimaryButton,
  LightGreenButton,
  SmallSecondaryButton,
  SmallSecondaryOutlineButton,
  SecondaryButton,
  SecondaryOutlineButton,
  WhiteButton,
};
