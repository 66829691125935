import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Feedback, Report } from '../app/datatypes';
import { api } from '../app/helper';

export const getFeedbackIndex = (f: (Feedback | {
  report_id: string;
  requirement_id: string;
  blob_id: number;
})): string => `${f.report_id}_${f.requirement_id}_${f.blob_id}`;

export interface ReportState {
  status: 'idle' | 'success' | 'loading' | 'error' | 'refreshing';
  report: Report | null;
  error: string;
  feedbacks: FeedbacksState;
}

export interface FeedbacksState {
  status: 'idle' | 'success' | 'loading' | 'error';
  list: { [index: string]: FeedbackState; } | null;
  error: string;
}

export interface FeedbackState {
  status: 'idle' | 'success' | 'loadingTrue' | 'loadingFalse' | 'error';
  feedback: Feedback;
  error: string;
}

const initialState = {
  status: 'idle',
  report: null,
  error: '',
  feedbacks: {
    status: 'idle',
    list: null,
    error: '',
  },
} as ReportState;

/**
 * Gets a single report from the REST API
 * @param report_id the id of the document
 */
export const getReport = createAsyncThunk<Report, {
  report_id: string;
}>('report/get_by_id', ({ report_id }) => api(`/api/v1/report/${report_id}`)
  .then((r) => r.json()).then((r) => r as Report));

export const getFeedbacks = createAsyncThunk<Feedback[]>(
  'report/get_feedbacks',
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as any;
    const reportState = state.report as ReportState;

    // eslint-disable-next-line no-underscore-dangle
    if (!reportState.report?._id) {
      throw new Error('Report not fetched yet.');
    }

    // eslint-disable-next-line no-underscore-dangle
    return api(`/api/v1/feedback/${reportState.report?._id}/`)
      .then((r) => r.json()).then((r) => r as Feedback[]);
  },
);

export const postFeedback = createAsyncThunk<Feedback, {
  report_id: string;
  blob_id: number;
  requirement_id: string;
  value: boolean;
}>(
  'report/post_feedback',
  (payload, thunkAPI) => api('/api/v1/feedback/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then((r) => r.json()).then(async () => {
    const rootState = thunkAPI.getState() as any;
    const state = rootState.report as ReportState;

    const feedBackIndex = getFeedbackIndex(payload);

    return {
      ...(state.feedbacks.list?.[feedBackIndex]?.feedback),
      ...payload,
    } as Feedback;
  }),
);
export const deleteFeedback = createAsyncThunk<Feedback, {
  report_id: string;
  blob_id: number;
  requirement_id: string;
}>(
  'report/delete_feedback',
  (payload, thunkAPI) => api(
    `/api/v1/feedback/${payload.report_id}/${payload.requirement_id}/${payload.blob_id}`,
    {
      method: 'DELETE',
    },
  ).then((r) => r.json()).then(async () => {
    const rootState = thunkAPI.getState() as any;
    const state = rootState.report as ReportState;

    const feedBackIndex = getFeedbackIndex(payload);

    return {
      ...(state.feedbacks.list?.[feedBackIndex]?.feedback),
      ...payload,
    } as Feedback;
  }),
);

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state, action) => {
      const reportId = action.meta.arg.report_id;
      // eslint-disable-next-line no-underscore-dangle
      if (state.report?._id === reportId) {
        // same report is being refreshed. do not remove it from state
        state.status = 'refreshing';
      } else {
        state.status = 'loading';
        state.feedbacks = initialState.feedbacks;
        state.report = null;
      }
    }).addCase(getReport.fulfilled, (state, action) => {
      state.report = action.payload;
      state.error = '';
      state.status = 'success';
    }).addCase(getReport.rejected, (state, action) => {
      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
      state.report = null;
      state.feedbacks = initialState.feedbacks;
    });

    builder.addCase(getFeedbacks.pending, (state) => {
      state.feedbacks.status = 'loading';
    }).addCase(getFeedbacks.fulfilled, (state, action) => {
      state.feedbacks.list = action.payload.reduce((feedList, feedback) => ({
        ...feedList,
        [getFeedbackIndex(feedback)]: {
          feedback,
          status: 'idle',
          error: '',
        } as FeedbackState,
      }), {});
      state.feedbacks.status = 'success';
      state.feedbacks.error = '';
    }).addCase(getFeedbacks.rejected, (state, action) => {
      state.feedbacks.error = action.error?.message || 'unknown error';
      state.feedbacks.status = 'error';
      state.feedbacks.list = null;
    });

    builder.addCase(postFeedback.pending, (state, action) => {
      const feedbackIndex = getFeedbackIndex(action.meta.arg);

      if (!state.feedbacks.list) {
        state.feedbacks.list = {};
      }

      state.feedbacks.list[feedbackIndex] = {
        ...state.feedbacks.list[feedbackIndex],
        status: action.meta.arg.value ? 'loadingTrue' : 'loadingFalse',
      };
    }).addCase(postFeedback.fulfilled, (state, action) => {
      const feedbackIndex = getFeedbackIndex(action.meta.arg);

      const feedback = action.payload;

      if (state.feedbacks.list) {
        state.feedbacks.list[feedbackIndex] = {
          feedback,
          status: 'success',
          error: '',
        };
      } else {
        throw new Error('feedbacks state not initialized');
      }
    }).addCase(postFeedback.rejected, (state, action) => {
      const feedbackIndex = getFeedbackIndex(action.meta.arg);

      if (!state.feedbacks.list) {
        state.feedbacks.list = {};
      }

      state.feedbacks.list[feedbackIndex] = {
        ...state.feedbacks.list[feedbackIndex],
        status: 'error',
        error: action.error?.message || 'unknown error',
      };
    });

    // delete feedback
    builder.addCase(deleteFeedback.pending, (state, action) => {
      const feedbackIndex = getFeedbackIndex(action.meta.arg);

      if (!state.feedbacks.list) {
        state.feedbacks.list = {};
      }

      state.feedbacks.list[feedbackIndex] = {
        ...state.feedbacks.list[feedbackIndex],
        status: state.feedbacks.list[feedbackIndex].feedback.value ? 'loadingTrue' : 'loadingFalse',
      };
    }).addCase(deleteFeedback.fulfilled, (state, action) => {
      const feedbackIndex = getFeedbackIndex(action.meta.arg);

      if (state.feedbacks.list) {
        delete state.feedbacks.list[feedbackIndex];
      } else {
        throw new Error('feedbacks state not initialized');
      }
    }).addCase(deleteFeedback.rejected, (state, action) => {
      const feedbackIndex = getFeedbackIndex(action.meta.arg);

      if (!state.feedbacks.list) {
        state.feedbacks.list = {};
      }

      state.feedbacks.list[feedbackIndex] = {
        ...state.feedbacks.list[feedbackIndex],
        status: 'error',
        error: action.error?.message || 'unknown error',
      };
    });
  },
});

export const {
  clear,
} = reportSlice.actions;

export default reportSlice.reducer;
