import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Carousel from 'react-slick';

import CategoryButton from '../categoryButton/CategoryButton';
import TopicButton from '../topicButton/TopicButton';

import styles from './FilterUI.module.css';
import { s } from '../../app/helper';

/**
 * interface of FilterButtonSelect - onUpdate is not properly implemented by now
 */
export interface FilterUIProps {
  onUpdate: (categories: string[], topics: string[]) => void;
  categories: string[];
  topics: string[];
  selectedCategories: string[];
  selectedTopics: string[];
}

/**
 * Filter UI
 */
const FilterUI: React.FC<FilterUIProps> = ({
  onUpdate,
  categories,
  topics,
  selectedCategories,
  selectedTopics,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const totalOptionsToDisplay = 5;

  const enableLeftArrow = currentImage > 0;
  const enableRightArrow = currentImage < topics.length - totalOptionsToDisplay;

  const carouselRef = useRef<Carousel>(null);

  useEffect(() => {
    carouselRef.current?.slickGoTo(topics.indexOf(selectedTopics[0]));
  }, [selectedCategories]);
  return (
    <div className={styles.filterUI}>
      <div className={styles.filterRow}>
        {categories?.map((cat) => (
          <CategoryButton
            isSelected={selectedCategories?.includes(cat)}
            title={cat}
            onButtonClick={() => {
              onUpdate([cat], selectedTopics);
            }}
          />
        ))}
      </div>
      <div className={s(styles.filterRow, styles.carouselRow)}>
        <FontAwesomeIcon
          icon={faAngleLeft}
          className={s(
            styles.topicCarouselNavigationIcon,
            !enableLeftArrow ? styles.topicCarouselNavigationIconDisabled : '',
          )}
          onClick={() => {
            if (enableLeftArrow) {
              carouselRef.current?.slickPrev();
            }
          }}
        />
        <Carousel
          ref={carouselRef}
          className={styles.topicCarousel}
          slidesToShow={totalOptionsToDisplay}
          arrows={false}
          infinite={false}
          swipe={false}
          slidesToScroll={3}
          beforeChange={(oldI, newI) => {
            if (newI > -1 && newI < topics.length) {
              setCurrentImage(newI);
            }
          }}
        >
          {topics?.map((topic) => (
            <TopicButton
              iconID={topic}
              isSelected={selectedTopics?.includes(topic)}
              onButtonClick={() => {
                onUpdate(selectedCategories, [topic]);
              }}
            />
          ))}
        </Carousel>
        <FontAwesomeIcon
          icon={faAngleRight}
          className={s(
            styles.topicCarouselNavigationIcon,
            !enableRightArrow ? styles.topicCarouselNavigationIconDisabled : '',
          )}
          onClick={() => {
            if (enableRightArrow) {
              carouselRef.current?.slickNext();
            }
          }}
        />
      </div>
    </div>
  );
};

export default FilterUI;
