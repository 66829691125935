import Anticompetetivebehavior from '../res/anti_competetive_behavior.svg';
import Anticorruption from '../res/anti_corruption.svg';
import Biodiversity from '../res/biodiversity.svg';
import Customerprotection from '../res/customer_protection.svg';
import Economicperformance from '../res/economic_performance.svg';
import Environmentalassessment from '../res/environmental_assessment.svg';
import Effluentsandwaste from '../res/effluents_and_waste.svg';
import Emissions from '../res/emissions.svg';
import Employmentandmanagement from '../res/employment_and_management.svg';
import Energy from '../res/energy.svg';
import Humanrights from '../res/human_rights.svg';
import Indirecteconomicimpacts from '../res/indirect_economic_impacts.svg';
import Localcommunities from '../res/local_communities.svg';
import Marketingandlabeling from '../res/marketing_and_labeling.svg';
import Marketpresence from '../res/market_presence.svg';
import Materials from '../res/materials.svg';
import Occupationalhealthandsafety from '../res/occupational_health_and_safety.svg';
import Procurementpractices from '../res/procurement_practices.svg';
import Publicpolicy from '../res/public_policy.svg';
import Securitypractices from '../res/security_practices.svg';
import Socioeconomiccompliance from '../res/socioeconomic_compliance.svg';
import Supplierenvironmental from '../res/supplier_social_assessment.svg';
import Tax from '../res/tax.svg';
import Trainingandeducation from '../res/training_and_education.svg';
import Water from '../res/water.svg';
import Associationandbargaining from '../res/association_and_bargaining.svg';

export const topicIdsToIcons = new Map([

  ['economic_performance', Economicperformance],
  ['market_presence', Marketpresence],
  ['indirect_economic_impacts', Indirecteconomicimpacts],
  ['procurement_practices', Procurementpractices],
  ['anti_corruption', Anticorruption],
  ['anti_competetive_behavior', Anticompetetivebehavior],
  ['tax', Tax],
  ['materials', Materials],
  ['energy', Energy],
  ['water', Water],
  ['biodiversity', Biodiversity],
  ['emissions', Emissions],
  ['effluents_and_waste', Effluentsandwaste],
  ['socioeconomic_compliance', Socioeconomiccompliance],
  ['supplier_social_assessment', Supplierenvironmental],
  ['employment_and_management', Employmentandmanagement],
  ['environmental_assessment', Environmentalassessment],
  ['occupational_health_and_safety', Occupationalhealthandsafety],
  ['training_and_education', Trainingandeducation],
  ['human_rights', Humanrights],
  ['association_and_bargaining', Associationandbargaining],
  ['security_practices', Securitypractices],
  ['local_communities', Localcommunities],
  ['public_policy', Publicpolicy],
  ['occupational_health_and_safety', Occupationalhealthandsafety],
  ['marketing_and_labeling', Marketingandlabeling],
  ['customer_protection', Customerprotection],
]);
