import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak, {
  StoredToken, storeToken,
} from './keycloak';
import App from './App';
import { Background } from './features/appPage/AppPage';
import LoadingModal from './features/loadingModal/LoadingModal';

const LoadingComponent = (): JSX.Element => (
  <Background fixedHeight>
    <LoadingModal show />
  </Background>
);

const KeycloakApp: React.FC = () => (
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      checkLoginIframe: false,
    }}
    // initOptions={{
    //  token: getToken().token,
    //  refreshToken: getToken().refreshToken,
    //  idToken: getToken().idToken,
    // }}
    // need to store the tokens so it is persisted between refreshes
    onEvent={(eventType, error) => {
      console.log(`event ${eventType}: ${error}`);
    }}
    onTokens={(tok) => storeToken(
      {
        idToken: tok.idToken,
        token: tok.token,
        refreshToken: tok.refreshToken,
      } as StoredToken,
    )}
    LoadingComponent={<LoadingComponent />}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactKeycloakProvider>
);

export default KeycloakApp;
