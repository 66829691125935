import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Document } from '../app/datatypes';
import { api } from '../app/helper';

export interface DocumentState {
  status: 'idle' | 'success' | 'loading' | 'error';
  document: Document | null;
  error: string;
}

const initialState = {
  status: 'idle',
  document: null,
  error: '',
} as DocumentState;

/**
 * Gets a single document from the REST API
 * @param document_id the id of the document
 */
export const getDocument = createAsyncThunk<Document, {
  report_id: string;
}>('document/get_by_id', ({ report_id }) => api(`/api/v1/report/${report_id}/document`)
  .then((r) => r.json())
  .then((r) => r as Document));

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getDocument.pending, (state) => {
      state.status = 'loading';
      state.document = null;
    }).addCase(getDocument.fulfilled, (state, action) => {
      state.document = action.payload;
      state.error = '';
      state.status = 'success';
    }).addCase(getDocument.rejected, (state, action) => {
      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
    });
  },
});

export const {
  clear,
} = documentSlice.actions;

export default documentSlice.reducer;
