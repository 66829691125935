import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n/config';

import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/FrutigerLTCom-Bold.ttf';
import './fonts/FrutigerLTCom-Roman.ttf';

import './css/index.css';
import './css/theme.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import KeycloakApp from './KeycloakApp';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <KeycloakApp />,
);
