import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CategoryButton.module.css';

export interface CategoryButtonProps {
  title: string,
  onButtonClick: () => void,
  isSelected: boolean,
}

/**
 * Button is made in three divs.
 * A parent div is containing a div for the category button itself
 * and a div which is only visible when categoryButton is selected
 */

const CategoryButton: React.FC<CategoryButtonProps> = ({
  title, onButtonClick, isSelected,
}) => {
  const [t] = useTranslation('categories');
  return (
    <div className={styles.categoryButtonContainer}>
      <div
        className={`${title === 'Social' ? styles.soziales : ''}  ${isSelected && title === 'Social' ? styles.activeSoziales : ''} ${title === 'Environmental' ? styles.enviro : ''}  ${isSelected && title === 'Environmental' ? styles.activeEnviro : ''} ${title === 'Economic' ? styles.eco : ''}  ${isSelected && title === 'Economic' ? styles.activeEco : ''}`}
        onClick={onButtonClick}
        aria-hidden="true"
      >
        {t(title)}
      </div>
      <div className={`${isSelected && title === 'Social' ? styles.pointerSoziales : ''} ${isSelected && title === 'Economic' ? styles.pointerEco : ''} ${isSelected && title === 'Environmental' ? styles.pointerEnviro : ''}`} />
    </div>
  );
};

export default CategoryButton;
