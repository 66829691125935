import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styles from './Oss.module.css';
import licenseToDisplay from '../../res/licensesFrontend.json';
import fontstyles from '../../features/text/Text.module.css';
import { s } from '../../app/helper';

/*
 * Renders Page with open source licenses
*/

const Oss: React.FC = () => {
  const [t] = useTranslation('common');
  const licenseToBeDisplayed = (component: string, license: string, url: string): JSX.Element => (
    <Card className={styles.license}>
      <Card.Body className={s(styles.licenseBody, fontstyles.text)}>
        <h5 className={styles.component}>{component}</h5>
        <h5>
          <Badge bg="secondary">{license}</Badge>
        </h5>
        <a aria-label="license-link" href={url}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </Card.Body>
    </Card>
  );
  return (
    <div className={styles.body}>
      <div className={s(styles.detailsHeader, fontstyles.HeaderPages)}>
        {t('open-source-licenses')}
      </div>
      {licenseToDisplay.map((e) => licenseToBeDisplayed(e.component, e.license, e.url))}
    </div>
  );
};
export default Oss;
