import React, { useEffect, useState, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Spinner, CloseButton, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { DocumentViewer } from '@cbojs/pdf-viewer';
import { s } from '../../app/helper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import ChecklistPredictions from '../../features/checklistPredictions/ChecklistPredictions';
import FilterUI from '../../features/filterUI/FilterUI';

import { getPdf } from '../../reducer/pdfSlice';
import { getDocument } from '../../reducer/documentSlice';
import { getPrediction } from '../../reducer/predictionSlice';
import { getFeedbacks, getReport } from '../../reducer/reportSlice';
import { getChecklist } from '../../reducer/checklistSlice';
import ReportLoader from '../../features/reportLoader/ReportLoader';
import styles from './Report.module.css';
import fontStyles from '../../features/text/Text.module.css';
import { getJob, Job } from '../../app/job';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ConfidenceLegend from '../../features/confidenceLegend/ConfidenceLegend';

const Report: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [t] = useTranslation('report');
  const { reportId } = params;
  const report = useAppSelector((state) => state.report.report);
  const reportGetStatus = useAppSelector((state) => state.report.status);
  const pdf = useAppSelector((state) => state.pdf.pdf);
  const document = useAppSelector((state) => state.document.document);
  const checklist = useAppSelector((state) => state.checklist.checklist);
  const prediction = useAppSelector((state) => state.prediction.prediction);

  const [job, setJob] = useState<Job | null>(null);

  // indicates whether the user has dismissed the warning of the document not looking
  // like a CSR report.
  const [dismissedDocError, setDismissedDocError] = useState<boolean>(false);

  /**
   * Does the actual report fetching
   */
  const fetchReport = useCallback(
    () => dispatch(getReport({ report_id: reportId! })),
    [dispatch, reportId],
  );
  /**
   * Does the actual report fetching
   */
  const asyncFetchJob = async (jobId: string): Promise<void> => {
    setJob(await getJob(jobId));
  };

  /**
   * fetches the report only. Report may not be available and we dont want to flood backend with
   * requests for predictions and the pdf
   */
  useEffect(() => {
    const promise = fetchReport();
    // cleans up any requests that don't finish before component is deconstructured
    return () => promise.abort();
  }, [fetchReport, reportId]);

  useEffect(() => {
    if (report?.available) {
      // report has been loaded / updated
      const docPromise = dispatch(getDocument({ report_id: reportId! }));
      const predPromise = dispatch(getPrediction({ report_id: reportId! }));
      const pdfPromise = dispatch(getPdf({ report_id: reportId! }));
      const checkPromise = dispatch(getChecklist({ checklist_id: report!.checklist }));
      const feedbacksPromise = dispatch(getFeedbacks());
      // cleans up any requests that don't finish before component is deconstructured
      return () => {
        docPromise.abort();
        predPromise.abort();
        pdfPromise.abort();
        checkPromise.abort();
        feedbacksPromise.abort();
      };
    }
    if (report?.available === false && reportGetStatus !== 'refreshing' && reportGetStatus !== 'loading') {
      // report is not available AND we are not loading/refreshing

      // get job info
      if (report?.job_id) {
        asyncFetchJob(report.job_id);
      }
      // schedule refresh
      const timeout = setTimeout(
        () => {
          fetchReport();
        },
        10000,
      );
      // cleaning up timeout
      return () => {
        clearTimeout(timeout);
      };
    }
    return () => {
    };
  }, [dispatch, fetchReport, report, reportId, reportGetStatus]);

  const [highlightedBlobId, setHighlightedBlobId] = useState<number>();

  const categoryData = checklist?.requirements?.reduce((oldCatData, currentReq) => {
    // get the already associated topics with the category
    // or get an empty array if none exist
    const catTopics = oldCatData.get(currentReq.category!) || [];

    // check the new requirement topic already exists or not
    // if yes return the old data, no need to add
    if (catTopics.some((cT) => (
      cT === currentReq.topic!
    ))) {
      return oldCatData;
    }

    // otherwise set the add the topics to the list and return
    return oldCatData.set(
      currentReq.category!,
      [...catTopics,
        currentReq.topic!,
      ],
    );
  }, new Map<string, string[]>()) || new Map<string, string[]>();
  // if checklist is null, sets a dummy

  const [selectedCategories, setSelectedCategories] = useState([] as string[]);
  const [selectedTopics, setSelectedTopics] = useState([] as string[]);

  const categories = Array.from(categoryData?.keys() || []);
  // constructs a list of all topics from the selected categories
  const topics = selectedCategories.reduce((oldTopics, currentCategory) => [...oldTopics,
    ...(categoryData?.get(currentCategory) || [])], [] as string[]);

  useEffect(() => {
    if (!selectedCategories?.length && categoryData?.size) {
      const tempTopics = categoryData.get('Environmental');
      setSelectedCategories(['Environmental']);
      setSelectedTopics(tempTopics ? [tempTopics[0]] : []);
    }
  }, [categoryData]);
  /**
   * Updates the highlighted blobs by the pdf viewer as well as the initial page
   * @param blobIds the ids of blobs to highlight
   */
  const jumpToBlob = (blobIds: Set<number>): void => {
    setHighlightedBlobId(blobIds.size > 0 ? Array.from(blobIds)[0] : undefined);
  };

  // controls whether loading screen is shown. Always shown when the page loads
  // set to false through the report loader
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true);

  // wait until all all resources were loaded from backend
  const requiredResources = [report, pdf, document, checklist, prediction];
  // checks the how many have been loaded
  const percentLoaded = (100 * requiredResources.filter((requirement) => requirement !== null).length)
    / requiredResources.length;
  if (showLoadingScreen) {
    return (
      <ReportLoader
        report={report}
        percentLoaded={percentLoaded}
        job={job}
        onReportReady={() => setShowLoadingScreen(false)}
      />
    );
  }

  return (
    <div className={styles.mainContainer}>
      <Row className={styles.Row}>
        <Col md={6} className={styles.requirementCol}>
          {report?.owner !== null && prediction?.is_csr_report === false && !dismissedDocError ? (
            <Alert variant="warning">
              <CloseButton onClick={() => {
                setDismissedDocError(true);
              }}
              />
              <div>
                {t('report-is-not-csr-warning')}
              </div>
            </Alert>
          ) : null}
          <div className={s(styles.ReportTitle)}>
            <div className={s(fontStyles.textBold, fontStyles.black)}>
              {report?.company}
              {' '}
              - Nachhaltigkeitsbericht
            </div>
            <div className={s(fontStyles.text, fontStyles.black)}>
              {report?.industry}
              {' '}
              |
              {' '}
              {report?.year}
            </div>
            <div className={styles.underline} />
          </div>
          <FilterUI
            onUpdate={
              (cats: string[], tops: string[]) => {
                let newTops = tops;
                if (cats !== selectedCategories) {
                  newTops = [categoryData.get(cats[0])?.[0]!];
                }
                setSelectedCategories(cats);
                setSelectedTopics(newTops);
              }
            }
            categories={categories}
            topics={topics}
            selectedCategories={selectedCategories}
            selectedTopics={selectedTopics}
          />
          <div className={styles.requirementList}>
            <ChecklistPredictions
              jumpToBlob={jumpToBlob}
              categoriesToFilter={selectedCategories}
              topicsToFilter={selectedTopics}
            />
          </div>
          <ConfidenceLegend />
        </Col>
        <Col md={6} className={styles.pdfViewerCol}>
          {pdf && document
            ? (
              <DocumentViewer
                className={styles.pdfViewer}
                document={{ pdfBase64: pdf }}
                rendererProps={{
                  focusedBlobId: `${highlightedBlobId}`,
                  highlightedBlobIds: [`${highlightedBlobId}`],
                  pageClassName: styles.pdfPage,
                  blobs: document.content.map((b) => ({
                    ...b,
                    type: b.blob_type,
                    // eslint-disable-next-line no-underscore-dangle
                    id: `${b.id_}`,
                    bounding_box: {
                      left: b.bounding_box.top_left_x,
                      top: b.bounding_box.top_left_y,
                      bottom: b.bounding_box.bottom_right_y,
                      right: b.bounding_box.bottom_right_x,
                    },
                  })),
                }}
                allowBlobInspectionMode={false}
              />
            )
            // document is loading
            : <Spinner animation="border" />}
        </Col>
      </Row>
    </div>
  );
};

export default Report;
