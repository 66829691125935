import impressumMD from './Impressum.md';
import datenschutzMD from './Datenschutz.md';
import nutzungsbedingungenMD from './Nutzungsbedingungen.md';

export { default as pdf_viewer } from './pdf_viewer.json';
export { default as requirement_predictions_box } from './requirement_predictions_box.json';
export { default as blob_render } from './blob_render.json';
export { default as language_selector } from './language_selector.json';
export { default as common } from './common.json';
export { default as categories } from './categories.json';
export { default as topics } from './topics.json';
export { default as report_card } from './report_card.json';
export { default as upload_document } from './upload_document.json';
export { default as intro } from './intro.json';
export { default as landing } from './landing.json';
export { default as report } from './report.json';
export { default as req_desc } from './req_desc.json';
export { default as req_short_desc } from './req_short_desc.json';
export { default as report_preview } from './report_preview.json';

export const impressum = { url: impressumMD };
export const datenschutz = { url: datenschutzMD };
export const nutzungsbedingungen = { url: nutzungsbedingungenMD };
