import React from 'react';
import {
  Container,
  Row,
  Col,
  ProgressBar,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Report } from '../../app/datatypes';
import { s } from '../../app/helper';
import BlurModal from '../blurModal/BlurModal';
import { SecondaryOutlineButton, PrimaryButton } from '../button/Button';
import { Job, JobStatus } from '../../app/job';

import styles from './ReportLoader.module.css';
import textStyles from '../text/Text.module.css';

interface ReportLoaderProps {
  report: Report | null
  percentLoaded: number;
  job: Job | null;
  onReportReady: () => void;
}

const ReportLoader: React.FC<ReportLoaderProps> = ({
  report, percentLoaded, job, onReportReady,
}) => {
  const [t] = useTranslation('report');
  const navigate = useNavigate();

  const reportStatus = (): React.ReactNode => {
    if (!report || (report.available)) {
      // report is being loaded
      return (
        <>
          <p className={s(styles.noLeftPad, textStyles.SubHeaderPages)}>
            {(percentLoaded < 100) ? `${t('loading-report')} (${percentLoaded}%)` : t('report-is-ready')}
          </p>
          {(percentLoaded >= 100) ? (
            <p className={s(styles.noLeftPad, textStyles.text)}>
              {t('click-to-continue')}
            </p>
          ) : null }
          <ProgressBar className={s(styles.reportProgress)}>
            <ProgressBar className={s(styles.reportProgressFill)} now={percentLoaded} />
          </ProgressBar>
        </>
      );
    }

    if (!report.available) {
      // report is not available

      if (job) {
        // job data is loaded

        if (job.status === JobStatus.STARTED || job.status === JobStatus.FINISHED) {
          // job is running
          return (
            <>
              <p className={s(styles.noLeftPad, textStyles.SubHeaderPages)}>
                {`${t('report-is-being-processed')} (${job.progress}%)`}
              </p>
              <p className={s(styles.noLeftPad, textStyles.text)}>
                {`${t('check-other-reports-while-processing')} (${job.progress}%)`}
              </p>
              <ProgressBar className={s(styles.reportProgress)}>
                <ProgressBar className={s(styles.reportProgressFill)} now={job.progress} />
              </ProgressBar>
            </>
          );
        }
        if (job.status === JobStatus.QUEUED) {
          // job is queued
          return (
            <p className={s(styles.noLeftPad, textStyles.SubHeaderPages)}>
              {`${t('report-is-queued')} (${job.progress})`}
            </p>
          );
        }
      }

      // either job failed or job could not be loaded
      return (
        <p className={s(styles.noLeftPad, textStyles.SubHeaderPages)}>{`${t('report-processing-failed')} `}</p>
      );
    }
    return null;
  };
  // <div className={styles.placeholder} />
  return (
    <BlurModal show>
      <Container className={styles.reportLoader}>
        <Row className={s(textStyles.HeaderPages, styles.customRow)}>
          {percentLoaded < 100 ? t('report-loader-header') : t('report-loaded-header')}
        </Row>
        <Row className={s(styles.customRow, styles.mainContentRow, 'justify-content-md-center')}>
          <Col xs={12} md={7} className={s(styles.mainContentCol)}>
            <iframe
              width="100%"
              className={styles.ytFrame}
              src="https://www.youtube-nocookie.com/embed/YHxYHGFvVdo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowFullScreen
            />
          </Col>
        </Row>
        <Row className={s(styles.customRow, textStyles.text)}>
          {reportStatus()}
        </Row>
        <Row className={s(styles.customRow, textStyles.text, styles.buttonRow)}>
          <SecondaryOutlineButton
            className={styles.button}
            onClick={() => navigate('/report')}
          >
            {t('back-to-reports')}
          </SecondaryOutlineButton>
          {percentLoaded === 100 ? (
            <PrimaryButton
              className={s(styles.button)}
              onClick={onReportReady}
            >
              {t('continue-to-report')}
            </PrimaryButton>
          ) : null }
        </Row>
      </Container>
    </BlurModal>
  );
};

export default ReportLoader;
