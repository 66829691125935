import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ns, { de } from './locales/namespace-defs';

const resources = {
  // en,
  de,
};

const defaultNS = 'common';

export const languageDisplayNames = new Map([
  // ['en', 'English'],
  ['de', 'Deutsch'],
]);

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    supportedLngs: Array.from(languageDisplayNames.keys()),
    debug: true,
    ns,
    resources,
  });

export default i18next;
export { resources, defaultNS };
