import React from 'react';

import styles from './Impressum.module.css';
import MarkdownPage from '../../features/markdownPage/MarkdownPage';

/*
 * Renders Page with open source licenses
*/

const Impressum: React.FC = () => <MarkdownPage pageKey="impressum" className={styles.impressumContainer} />;

export default Impressum;
