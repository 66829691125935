import React from 'react';
import MarkdownPage from '../../features/markdownPage/MarkdownPage';
import styles from './Datenschutz.module.css';

/*
 * Renders Page with open source licenses
*/

const Datenschutz: React.FC = () => <MarkdownPage pageKey="datenschutz" className={styles.datenschutzContainer} />;

export default Datenschutz;
