import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Report } from '../app/datatypes';
import { api } from '../app/helper';

export interface ReportsState {
  status: 'idle' | 'success' | 'loading' | 'error';
  reports: Report[];
  error: string;
}

const initialState: ReportsState = {
  status: 'idle',
  reports: [],
  error: '',
} as ReportsState;

/**
 * Gets a list of all the documents from the REST API
 */
export const getReports = createAsyncThunk(
  'document/fetch',
  () => api('/api/v1/report/')
    .then((response) => response.json())
    .then((docs) => docs.reports as Report[]),
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    // temporary test reducer, replace with actions for updating the document lists locally
    clear: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getReports.pending, (state: ReportsState) => {
      state.status = 'loading';
    }).addCase(getReports.fulfilled, (state: ReportsState, action) => {
      state.status = 'success';
      state.reports = action.payload;
    }).addCase(getReports.rejected, (state, action) => {
      state.error = action.error?.message || 'unknown error';
      state.status = 'error';
    });
  },
});

export const {
  clear,
} = reportsSlice.actions;

export default reportsSlice.reducer;
