import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import { s } from '../../app/helper';

import fontStyles from '../text/Text.module.css';
import styles from './ConfidenceLegend.module.css';

const ConfidenceLegend = (): JSX.Element => (
  <div className={styles.confidenceLegend}>
    <span className={s(fontStyles.text, styles.legendTitle)}>Vorhersagegenauigkeit</span>
    <div className={styles.blobConfidenceContainer}>
      <div className={styles.blobConfidence}>
        <span className={s(styles.blobConfidencePercent, fontStyles.SubHeaderPopups)}>
          <FontAwesomeIcon icon={faArrowUp} />
        </span>
        <span className={s(fontStyles.smallText, styles.legendText)}>
          hoch
        </span>
      </div>
      <div className={styles.blobConfidence}>
        <span className={s(styles.blobConfidencePercent, fontStyles.SubHeaderPopups)}>
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
        <span className={s(fontStyles.smallText, styles.legendText)}>
          mittel
        </span>
      </div>
      <div className={styles.blobConfidence}>
        <span className={s(styles.blobConfidencePercent, fontStyles.SubHeaderPopups)}>
          <FontAwesomeIcon icon={faArrowDown} />
        </span>
        <span className={s(fontStyles.smallText, styles.legendText)}>
          niedrig
        </span>
      </div>
    </div>
  </div>
);

export default ConfidenceLegend;
