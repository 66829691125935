import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { DocumentViewer } from '@cbojs/pdf-viewer';
import BlurModal from '../blurModal/BlurModal';
import { PrimaryButton } from '../button/Button';

import styles from './ReportPreview.module.scss';
import fontStyles from '../text/Text.module.css';
import { s } from '../../app/helper';
import { getDocument } from '../../reducer/documentSlice';
import { getPdf } from '../../reducer/pdfSlice';
import { getReport } from '../../reducer/reportSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

type ReportPreviewProps = {
  reportId: string | null;
  onHide: () => void;
};

const ReportPreview: React.FC<ReportPreviewProps> = (
  {
    reportId,
    onHide,
  },
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pdf = useAppSelector((state: RootState) => state.pdf.pdf);
  const report = useAppSelector((state: RootState) => state.report.report);
  const document = useAppSelector((state: RootState) => state.document.document);

  const pdfState = useAppSelector((state: RootState) => state.pdf);
  const reportState = useAppSelector((state: RootState) => state.report);
  const documentState = useAppSelector((state: RootState) => state.document);

  const [t] = useTranslation('report_preview');

  useEffect(() => {
    if (!reportId) {
      onHide();
      return () => {
      };
    }

    const reportPromise = dispatch(getReport({ report_id: reportId! }));
    const docPromise = dispatch(getDocument({ report_id: reportId! }));
    const pdfPromise = dispatch(getPdf({ report_id: reportId! }));

    return () => {
      reportPromise.abort();
      docPromise.abort();
      pdfPromise.abort();
    };
  }, [dispatch, onHide, reportId]);

  return (
    <BlurModal
      show={!!reportId}
      onHide={onHide}
      backdropClassName={styles.reportPreviewBackdrop}
    >
      <Col
        xs={{ span: 8, offset: 2 }}
        lg={{ span: 6, offset: 3 }}
        className={styles.reportPreviewContainer}
      >
        <Row className={s(
          styles.reportPreviewTitle,
          fontStyles.HeaderPopups,
        )}
        >
          {t('preview')}
        </Row>
        <Row className={s(
          styles.reportPreviewSubTitle,
          fontStyles.SubHeaderPopups,
        )}
        >
          {report?.company}
          &nbsp;
          {t('report')}
          <PrimaryButton
            className={styles.closePreviewModal}
            onClick={onHide}
          >
            <FontAwesomeIcon
              icon={faTimes}
              size="2x"
            />
          </PrimaryButton>
        </Row>
        <Row className={styles.reportPreviewPdf}>
          {
            pdfState.status === 'success' && pdf && document
              ? (
                <DocumentViewer
                  document={{ pdfBase64: pdf }}
                  className={styles.pdfPreviewer}
                  rendererProps={{
                    pageClassName: styles.pdfPageClassName,
                    scale: 0.8,
                    blobs: document.content.map((b) => ({
                      ...b,
                      type: b.blob_type,
                      // eslint-disable-next-line no-underscore-dangle
                      id: `${b.id_}`,
                      bounding_box: {
                        left: b.bounding_box.top_left_x,
                        top: b.bounding_box.top_left_y,
                        bottom: b.bounding_box.bottom_right_y,
                        right: b.bounding_box.bottom_right_x,
                      },
                    })),
                  }}
                  allowBlobInspectionMode={false}
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={{
                      error: faCircleExclamation,
                      success: faCircleExclamation,
                      idle: faHourglassHalf,
                      loading: faCircleNotch,
                    }[pdfState.status]}
                    color="white"
                    size="3x"
                    spin={pdfState.status === 'loading'}
                  />
                  <div className={s(styles.reportPreviewError, fontStyles.SubHeaderPopups)}>
                    {reportState.status === 'error' && `REPORT: ${t('error-loading')}`}
                    <br />
                    {pdfState.status === 'error' && `PDF: ${t('error-loading')}`}
                    <br />
                    {documentState.status === 'error' && `DOCUMENT: ${t('error-loading')}`}
                  </div>
                </>
              )
          }
        </Row>
        <Row className={styles.reportPreviewButtonContainer}>
          <PrimaryButton
            className={styles.reportPreviewButton}
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              navigate(`/report/${reportId}/`);
            }}
          >
            {t('select-document')}
          </PrimaryButton>
        </Row>
      </Col>
    </BlurModal>
  );
};

export default ReportPreview;
