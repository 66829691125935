import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpFromBracket,
  faTimes,
  faTriangleExclamation,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import { Col, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import BlurModal from '../blurModal/BlurModal';
import { api, APIError, s } from '../../app/helper';
import LoadingModal from '../loadingModal/LoadingModal';
import { PrimaryButton } from '../button/Button';
import {
  GroupedOption,
  SelectOption,
  UploadFormCheckArea,
  UploadFormSelect,
  UploadFormTextInput,
} from '../uploadFormInput/UploadFormInput';

import branch_options from '../../app/branch_options.json';

import styles from './UploadDocument.module.css';
import fontStyles from '../text/Text.module.css';

type UploadDocumentProps = {
  showUploadDocument: boolean;
  onHide: () => void;
};

type UploadData = {
  company: string;
  branch: string;
  year: number;
};

const branchOptions = branch_options as GroupedOption[];
const optionsToSelectOptions = (options: string[]): SelectOption[] => options.map((op) => ({
  label: op,
  value: op
    .toLowerCase()
    .replaceAll(/\s+/g, '_')
    .replaceAll('&', 'and'),
}));

const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const UploadDocument: React.FC<UploadDocumentProps> = ({ showUploadDocument, onHide }) => {
  const [t] = useTranslation('upload_document');

  const [uploadData, setUploadData] = useState<UploadData>({
    company: '',
    branch: '',
    year: 2020,
  });
  const [uploadFile, setUploadFile] = useState<File>();
  const [tosValue, setTosValue] = useState(false);
  const [selectOptions, setSelectOptions] = useState<{
    [key in keyof UploadData]?: SelectOption[] | GroupedOption[]
  }>({});

  // if true, shows warning next to fields when its invalid
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const [uploadingReport, setUploadingReport] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const inputFileElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => setSelectOptions({ year: optionsToSelectOptions(['2019', '2020', '2021', '2022']) }))();
  }, []);

  const handleUploadDataChange = (
    key: keyof UploadData,
  ) => (
    value: string | number,
  ): void => setUploadData((uD) => ({
    ...uD,
    [key]: typeof uD[key] === 'number' ? Number(value) : value,
  }));

  const fileTo64 = async (file: File): Promise<string> => new Promise<string>(
    (resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onerror = (error) => reject(error);
      // need to remove a prefix added by readAsDataURL so we get a base64 st
      fileReader.onload = () => {
        const dataUrl = fileReader.result as string;
        const b64str = dataUrl.split(',').pop() as string;
        resolve(b64str);
      };
    },
  );

  const handleUploadFileChange = (): void => setUploadFile(inputFileElement.current?.files?.[0]);

  /**
   * Adds a report into the backend
   * Uploads a report with the details as specified in the {@link DocumentUploadData} type
   *
   * @param data the document data to add
   * May Raise APIError
   */
  const uploadReport = async (file: File, reportData: UploadData): Promise<string> => {
    const payload = {
      checklist: 'GRI',
      industry: reportData.branch,
      year: reportData.year,
      company: reportData.company,
      pdf_filename: file.name,
      pdf64: await fileTo64(file),
    };
    return api('/api/v1/report/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      // todo handle file data separately
      body: JSON.stringify(payload),
    }).then((resp) => resp.json()).catch((err) => {
      throw err;
    });
  };

  /**
   * Called when user clicks on upload report
   */
  const handleUpload = async (): Promise<void> => {
    if (uploadingReport) {
      return;
    }
    setShowValidationErrors(true);
    // check if user clicked on `agree checkbox`
    if (!tosValue) {
      throw new Error('Conditions not Accepted');
    }

    // do we need to check if industry was selected?

    if (uploadData.company.length === 0 || !uploadFile) {
      setErrorMessage(t('report-not-uploaded-missing-data-warning'));
      return;
    }

    setUploadingReport(true);
    try {
      const reportUuid = await uploadReport(uploadFile, uploadData);
      setUploadingReport(true);
      // redirect to report page
      navigate(`/report/${reportUuid}`);
    } catch (e) {
      if (e instanceof APIError && e.code === 413) {
        setErrorMessage(t('file-size-warning'));
      } else {
        setErrorMessage(t('unexpected-error'));
      }
      setUploadingReport(false);
    }
  };

  const uploadForm = (): React.ReactNode => (
    <>
      <div className={styles.uploadModalHeader}>
        <span className={s(fontStyles.HeaderPopups, styles.uploadModalTitle)}>
          {t('upload-new-doc')}
        </span>
        <PrimaryButton
          className={styles.closeUploadModal}
          onClick={onHide}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className={styles.crossIcon}
            size="2x"
          />
        </PrimaryButton>
      </div>
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleUpload();
      }}
      >
        <Row className={styles.uploadModalRow}>
          <Col md={7} className={styles.uploadModalUploadButtonContainer}>
            <input
              type="file"
              ref={inputFileElement}
              style={{ display: 'none' }}
              onChange={handleUploadFileChange}
              accept="application/pdf"
            />
            <PrimaryButton
              className={styles.uploadModalUploadButton}
              onClick={() => {
                inputFileElement.current?.click();
              }}
            >
              <FontAwesomeIcon icon={faArrowUpFromBracket} size="3x" />
            </PrimaryButton>
            <span className={s(styles.uploadModalUploadCaption, fontStyles.SubHeaderPopups)}>
              {t('upload-doc')}
            </span>
            {showValidationErrors && !uploadFile && (
              <span className={s(styles.error, fontStyles.text)}>
                <FontAwesomeIcon icon={faTriangleExclamation} />
                {' '}
                {t('choose-file-warning')}
              </span>
            )}
            {uploadFile && uploadFile?.size > 1024 * 1024 * 15 && (
              <span className={s(styles.warning, fontStyles.text)}>
                <FontAwesomeIcon icon={faTriangleExclamation} />
                {' '}
                {t('file-size-warning')}
              </span>
            )}
            <div className={s(styles.selectedFileDetails, fontStyles.smallButton)}>
              <span>
                {t('file-size-info')}
                :
              </span>
              <span>
                15 MB
              </span>
            </div>
            {uploadFile && (
              <div className={s(styles.selectedFileDetails, fontStyles.smallButton)}>
                <span>
                  File Name:
                  <br />
                  File Size:
                </span>
                <span>
                  {uploadFile?.name}
                  <br />
                  {formatBytes(uploadFile?.size)}
                </span>
              </div>
            )}
          </Col>
          <Col className={styles.uploadModalForm} md={5}>
            <UploadFormTextInput
              label={t('company')}
              warning={
                (showValidationErrors && uploadData.company === '') ? t('input-company-name-warning') : undefined
              }
              value={uploadData.company}
              onChange={handleUploadDataChange('company')}
            />
            <UploadFormSelect
              options={branchOptions}
              label={t('branch')}
              value={uploadData.branch}
              onChange={handleUploadDataChange('branch')}
            />
            <UploadFormSelect
              options={selectOptions.year || []}
              label={t('year')}
              value={`${uploadData.year}`}
              onChange={handleUploadDataChange('year')}
            />
          </Col>
        </Row>
        <UploadFormCheckArea
          prompt={t('tos')}
          value={tosValue}
          labelClassName={s(styles.tosLabel, fontStyles.text)}
          checkClassName={styles.tosCheck}
          className={styles.tosBox}
          onChange={setTosValue}
        />
        <Row className={styles.uploadModalRow}>
          <Col xs={12} className={styles.uploadModalButtonBar}>
            <PrimaryButton
              type="submit"
              disabled={!tosValue}
              className={styles.uploadModalButtonBarButton}
            >
              {t('continue')}
            </PrimaryButton>
            <PrimaryButton
              className={styles.uploadModalButtonBarButton}
              onClick={onHide}
            >
              {t('back-overview')}
            </PrimaryButton>
          </Col>
        </Row>
        <Row className={styles.uploadModalRow}>
          {errorMessage ? (
            <div className={s(styles.errorBox)}>
              <div className={styles.errorBoxHeader}>
                <span className={s('fa-stack fa-1x', styles.exclamationIcon)}>
                  <FontAwesomeIcon className={s('fa-stack-2x')} icon={faCircle} />
                  <FontAwesomeIcon
                    className={s('fa-stack-1x')}
                    icon={faExclamation}
                  />
                </span>
                <span className={s(fontStyles.SubHeaderPages)}>
                  {t('an-error-occurred')}
                </span>
              </div>
              {errorMessage}
            </div>
          ) : null}
        </Row>
      </Form>
    </>
  );

  return (
    <BlurModal
      show={showUploadDocument}
      onHide={onHide}
      backdropClassName={styles.uploadModalBackdrop}
    >
      <div className={s(styles.uploadModal)}>
        <Container className={s(styles.uploadModalContents)}>
          {
            uploadingReport ? (
              <p>
                <LoadingModal show />
              </p>
            ) : uploadForm()
          }
        </Container>
      </div>
    </BlurModal>
  );
};

export default UploadDocument;
