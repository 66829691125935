import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import RequirementPredictionBox from '../requirementPredictionBox/RequirementPredictionBox';
import { RootState } from '../../app/store';
import {
  Blob,
  Checklist,
  Document,
  Prediction,
  Recommendation,
  Report,
  Requirement,
} from '../../app/datatypes';
import { useAppSelector } from '../../app/hooks';
import { FeedbacksState, FeedbackState, getFeedbackIndex } from '../../reducer/reportSlice';

interface ChecklistPredictionsProps {
  jumpToBlob: (blobIds: Set<number>, page: number) => void,
  categoriesToFilter: string[],
  topicsToFilter: string[]
}

type ChecklistPredictionType = {
  [requirement_id: string]: {
    requirement: Requirement,
    rbMap: { recommendation: Recommendation; blob: Blob; feedback?: FeedbackState; }[];
  }
};

/**
 * Combines a Document, Prediction and Checklist for showing them in {@link ChecklistPredictions}.
 */
const generateChecklistPredictions = (
  report: Report | null,
  document: Document | null,
  pred: Prediction | null,
  checklist: Checklist | null,
  feedbacks: FeedbacksState | null,
): ChecklistPredictionType => {
  if (document === null || checklist === null || pred === null || report === null) {
    return {} as ChecklistPredictionType;
  }

  return Object.entries(pred.req2blob || {})
    .reduce((previousValue, [r_id, rs]) => {
      const newVal = { ...previousValue } as ChecklistPredictionType;

      if (rs.length === 0) {
        // req2blob for this require,ent is empty
        return newVal;
      }
      /* eslint-disable no-underscore-dangle, @typescript-eslint/naming-convention */
      const requirement = checklist.requirements.find((r) => r.id_ === r_id)!;
      if (requirement === undefined) {
        console.log(`requirement with id (${r_id}) not found. Ignoring recommendation`);
        return newVal;
      }

      newVal[r_id] = {
        rbMap: rs.map((r) => ({
          recommendation: r,
          blob: document.content?.find((b) => b.id_ === r.blob_id)!,
          feedback: feedbacks?.list?.[getFeedbackIndex({
            report_id: report._id,
            requirement_id: r.requirement_id,
            blob_id: r.blob_id,
          })],
        })),
        requirement,
      };
      /* eslint-enable no-underscore-dangle, @typescript-eslint/naming-convention */

      return newVal;
    }, {});
};

/**
 * Memoized selector for {@link ChecklistPredictions} component.
 */
const checklistPredictionSelector = createSelector(
  (state: RootState): Report | null => state.report.report,
  (state: RootState): Document | null => state.document.document,
  (state: RootState): Prediction | null => state.prediction.prediction,
  (state: RootState): Checklist | null => state.checklist.checklist,
  (state: RootState): FeedbacksState | null => state.report.feedbacks,
  generateChecklistPredictions,
);

/**
 * Render predictions for the requirements in the checklist
 */
const ChecklistPredictions: React.FC<ChecklistPredictionsProps> = ({
  jumpToBlob,
  categoriesToFilter,
  topicsToFilter,
}) => {
  const checklistPrediction = useAppSelector(checklistPredictionSelector);

  /**
   *1. When the categories (topics) array is empty,
   we apply no filtering on categories (array).
   If one of the provided arrays is empty
   but the other isn't we still filter based on the values provided in the non empty array

   2. When the categories (topics) array is not empty,
   we only show requirements whose category (topic) value is present in the corresponding array
   */

  /* eslint-disable @typescript-eslint/no-unused-vars */
  let filteredRequirements = categoriesToFilter.length > 0 ? Object.entries(checklistPrediction)
    .filter(([requirement_id, rs]) => categoriesToFilter
      .includes(rs.requirement.category as string)) : Object.entries(checklistPrediction);

  filteredRequirements = topicsToFilter.length > 0 ? filteredRequirements
    .filter(([requirement_id, rs]) => topicsToFilter
      .includes(rs.requirement.topic as string)) : filteredRequirements;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  return (
    <>
      {filteredRequirements.sort().map(([requirement_id, rs]) => (
        <RequirementPredictionBox
          key={`req-pred-${requirement_id}`}
          id={requirement_id}
          predictions={rs.rbMap}
          jumpToBlob={jumpToBlob}
        />
      ))}
    </>
  );
};

export default ChecklistPredictions;
