import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from 'react-bootstrap';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

import { s } from '../../app/helper';

import styles from './MarkdownPage.module.css';

type MarkdownPageProps = {
  pageKey: string;
  className?: string;
};
const MarkdownPage: React.FC<MarkdownPageProps> = (
  {
    pageKey,
    className,
  },
) => {
  const [t] = useTranslation(pageKey);

  const [markdownText, setMarkdownText] = useState('');

  useEffect(() => {
    (async () => {
      const mdContent = await fetch(t('url')).then((res) => res.text());
      setMarkdownText(mdContent);
    })();
  }, [t]);

  return (
    <Container fluid className={s(styles.markdownPage, className!)}>
      <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>
        {markdownText}
      </ReactMarkdown>
    </Container>
  );
};

MarkdownPage.defaultProps = {
  className: '',
};

export default MarkdownPage;
