import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reportsReducer from '../reducer/reportsSlice';
import reportReducer from '../reducer/reportSlice';
import documentReducer from '../reducer/documentSlice';
import predictionReducer from '../reducer/predictionSlice';
import checklistReducer from '../reducer/checklistSlice';
import pdfReducer from '../reducer/pdfSlice';

export const store = configureStore({
  reducer: {
    pdf: pdfReducer,
    reports: reportsReducer,
    report: reportReducer,
    document: documentReducer,
    prediction: predictionReducer,
    checklist: checklistReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
RootState,
unknown,
Action<string>
>;
