import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {
  OverlayTrigger, Pagination, Tooltip, Card, Collapse,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BlobRender from '../blobRender/BlobRender';

import { Blob, Recommendation } from '../../app/datatypes';
import { s } from '../../app/helper';

import styles from './RequirementPredictionBox.module.css';
import fontStyles from '../text/Text.module.css';
import { FeedbackState } from '../../reducer/reportSlice';

/**
 * interface for requirement predictions props
 */
interface RequirementPredictionBoxProps {
  id: string;
  predictions: { recommendation: Recommendation, blob: Blob; feedback?: FeedbackState; }[];
  jumpToBlob: (blobIds: Set<number>, page: number) => void;
}

/**
 * size of the page
 */
const pageSize = 8;
/**
 * Render requirement prediction box
 * @param id is the id of the requirement
 * @param predictedBlobs are the predictions of the blobs
 * @param jumpToBlob function called when blob should be jumped to.
 */
const RequirementPredictionBox: React.FC<RequirementPredictionBoxProps> = ({
  id,
  predictions,
  jumpToBlob,
}: RequirementPredictionBoxProps): JSX.Element => {
  const [openPrediction, setOpenPrediction] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pages = Math.ceil(predictions.length / pageSize);

  const [t] = useTranslation('requirement_predictions_box');
  const [shortDescriptionT] = useTranslation('req_short_desc');
  const [descriptionT] = useTranslation('req_desc');
  /**
   * handles all page change events
   *
   * @param pageNum the new page number
   */
  const handlePageChange = (pageNum: number): void => {
    if (pageNum !== currentPage) {
      setCurrentPage((cP) => (pageNum < 1 || pageNum > pages ? cP : pageNum));
    }
  };

  /**
   * get pagination component
   */
  const getPagination = (): JSX.Element[] => {
    const items = [];

    if (pages <= 10) {
      for (let i = 1; i <= pages; i += 1) {
        items.push(
          <Pagination.Item
            key={`pagination-item-${i}`}
            id={`pagination-item-${i}`}
            onClick={() => handlePageChange(i)}
            active={i === currentPage}
          >
            {i}
          </Pagination.Item>,
        );
      }
    }

    if (pages > 10) {
      items.push(
        <Pagination.Prev
          key="pagination-prev"
          disabled={currentPage <= 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />,
        <Pagination.Item
          key="pagination-item-1"
          id="pagination-item-1"
          onClick={() => handlePageChange(1)}
          active={currentPage === 1}
        >
          {1}
        </Pagination.Item>,
      );

      if (currentPage < 5) {
        for (let i = 2; i <= 6; i += 1) {
          items.push(
            <Pagination.Item
              key={`pagination-item-${i}`}
              id={`pagination-item-${i}`}
              onClick={() => handlePageChange(i)}
              active={i === currentPage}
            >
              {i}
            </Pagination.Item>,
          );
        }
        items.push(<Pagination.Ellipsis key="pagination-ellipsis-prev" />);
      } else if (currentPage > pages - 5) {
        items.push(<Pagination.Ellipsis key="pagination-ellipsis-next" />);
        for (let i = pages - 5; i <= pages - 1; i += 1) {
          items.push(
            <Pagination.Item
              key={`pagination-item-${i}`}
              id={`pagination-item-${i}`}
              onClick={() => handlePageChange(i)}
              active={i === currentPage}
            >
              {i}
            </Pagination.Item>,
          );
        }
      } else {
        items.push(<Pagination.Ellipsis key="pagination-ellipsis-prev-1" />);
        for (let i = currentPage - 1; i <= currentPage + 2; i += 1) {
          items.push(
            <Pagination.Item
              key={`pagination-item-${i}`}
              id={`pagination-item-${i}`}
              onClick={() => handlePageChange(i)}
              active={i === currentPage}
            >
              {i}
            </Pagination.Item>,
          );
        }
        items.push(<Pagination.Ellipsis key="pagination-ellipsis-next-1" />);
      }

      items.push(
        <Pagination.Item
          key={`pagination-item-${pages}`}
          id={`pagination-item-${pages}`}
          onClick={() => handlePageChange(pages)}
          active={pages === currentPage}
        >
          {pages}
        </Pagination.Item>,
        <Pagination.Next
          key="pagination-next"
          disabled={currentPage >= pages}
          onClick={() => handlePageChange(currentPage + 1)}
        />,
      );
    }

    return items;
  };

  return (
    <div className={styles.predictionBoxContainer} id={id} data-testid={`requirement-box-${id}`}>
      <Card className={styles.predictionBox}>
        <Card.Header className={styles.predictionHeader} onClick={() => setOpenPrediction(!openPrediction)}>
          <OverlayTrigger
            trigger={['hover', 'click']}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{!openPrediction ? t('show-relevant-blobs') : t('hide-relevant-blobs')}</Tooltip>}
          >
            <div className={styles.expandArrow}>
              <FontAwesomeIcon
                icon={faAngleDown}
                className={s(styles.expandArrowIcon, openPrediction ? styles.flipped : '')}
              />
            </div>
          </OverlayTrigger>
          <h5 className={styles.predictionHeaderTitle}>
            {[id, shortDescriptionT(id)].join(' ')}
          </h5>
          <div className={s(styles.predictionBlobCount, fontStyles.text)}>
            {predictions.length}
          </div>
        </Card.Header>
        <Collapse in={openPrediction}>
          <Card.Body className={styles.predictionBoxBody}>
            <div className={styles.containerDescription}>
              <div className={s(styles.headerDescription, fontStyles.info)}>
                {t('description')}
              </div>
              <pre className={s(styles.bodyDescription, fontStyles.text)}>
                {descriptionT(id)}
              </pre>
            </div>
            {predictions
              .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map(({ blob, recommendation, feedback }) => (
                <BlobRender
                  // eslint-disable-next-line no-underscore-dangle
                  key={`b-${blob.id_}`}
                  blob={blob}
                  showAccuracy
                  recommendation={recommendation}
                  feedback={feedback}
                  jumpToBlob={jumpToBlob}
                />
              ))}
            <Pagination size="sm" className={styles.blobPagination}>
              {getPagination()}
            </Pagination>
          </Card.Body>
        </Collapse>
      </Card>
    </div>
  );
};

export default RequirementPredictionBox;
