/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { s } from '../../app/helper';

import styles from './UploadFormInput.module.scss';
import fontStyles from '../text/Text.module.css';

export interface SelectOption {
  label: string;
  value: string;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: SelectOption[];
}

type UploadFormLabelProps = {
  label?: string;
  className?: string;
  warning?: string;
};

type UploadFormTextInputProps = {
  value?: string;
  onChange?: (newVal: string) => void;
} & UploadFormLabelProps;

type UploadFormSelectProps = {
  value?: string;
  onChange?: (newVal: string) => void;
  options: SelectOption[] | GroupedOption[];
} & UploadFormLabelProps;

const UploadFormInput: React.FC<UploadFormLabelProps & PropsWithChildren> = ({
  label,
  children,
  className,
  warning,
}) => (
  <Form.Group className={s(className!, styles.uploadFormInputGroup)}>
    {label && (
      <Form.Label
        className={s(styles.uploadFormLabel, fontStyles.SubHeaderPopups)}
      >
        {label}
        {' '}
        {warning && (
          <span className={s(styles.warning, fontStyles.text)}>
            <FontAwesomeIcon icon={faTriangleExclamation} />
            {' '}
            {warning}
          </span>
        )}
      </Form.Label>
    )}
    {children}
  </Form.Group>
);

UploadFormInput.defaultProps = {
  label: '',
  className: '',
  warning: undefined,
};

const UploadFormTextInput: React.FC<UploadFormTextInputProps> = ({
  value,
  onChange,
  ...inputProps
}) => (
  <UploadFormInput {...inputProps}>
    <Form.Control
      type="text"
      className={s(styles.uploadFormInput, styles.uploadFormTextInput, fontStyles.text)}
      value={value}
      onChange={(newVal) => onChange?.(newVal.target.value)}
    />
  </UploadFormInput>
);

const UploadFormSelect: React.FC<UploadFormSelectProps> = ({
  options,
  value,
  onChange,
  ...inputProps
}) => (
  <UploadFormInput {...inputProps}>
    <Select<SelectOption, false, GroupedOption>
      classNamePrefix="form-select"
      placeholder=""
      className={s(styles.uploadFormSelect, fontStyles.text)}
      options={options}
      value={options.length > 0
        ? ('value' in options[0] ? options as SelectOption[]
          : (options as GroupedOption[])
            .reduce((allOps, op) => (
              [...allOps, ...op.options as SelectOption[]]
            ), [] as SelectOption[]))
          .find((op) => op.value === value) : undefined}
      onChange={(nV) => onChange?.(nV?.value!)}
    />
  </UploadFormInput>
);

UploadFormTextInput.defaultProps = {
  label: '',
  value: '',
  warning: undefined,
  onChange: () => {
  },
  className: '',
};

UploadFormSelect.defaultProps = {
  label: '',
  value: '',
  warning: undefined,
  onChange: () => {
  },
  className: '',
};

type UploadFormCheckProps = {
  value?: boolean;
  prompt?: string;
  onChange?: (val: boolean) => void;
  labelClassName?: string;
  checkClassName?: string;
  className?: string;
};

const UploadFormCheckArea: React.FC<UploadFormCheckProps> = ({
  value,
  prompt,
  onChange,
  labelClassName,
  checkClassName,
  className,
}) => {
  const checkId = `form-check${Math.random().toString(16).slice(2)}`;
  return (
    <Form.Check
      type="checkbox"
      className={s(className!, styles.uploadFormInputGroup, styles.uploadFormInputGroupCheck)}
      checked={value}
      onChange={(e) => onChange?.(e.target.checked)}
    >
      <Form.Check.Input
        type="checkbox"
        id={checkId}
        className={s(
          checkClassName!,
          styles.uploadFormCheck,
          styles.uploadFormInput,
        )}
        onChange={(e) => onChange?.(e.target.checked)}
      />
      <Form.Check.Label
        className={s(labelClassName!, styles.uploadFormCheckLabel)}
        htmlFor={checkId}
      >
        {prompt}
      </Form.Check.Label>
    </Form.Check>
  );
};

UploadFormCheckArea.defaultProps = {
  value: false,
  prompt: '',
  onChange: () => {
  },
  labelClassName: '',
  checkClassName: '',
  className: '',
};

export {
  UploadFormTextInput,
  UploadFormSelect,
  UploadFormCheckArea,
};
